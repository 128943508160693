import type { ToasterToast } from "@/components/ui/use-toast";

export const toastMessages: Record<string, Partial<ToasterToast>> = {
  // Error messages
  invalid_magic_link: {
    description:
      "The sign in link you used is invalid or has expired. Please request a new one.",
    variant: "destructive",
    title: "Error",
  },
  verification_failed: {
    description: "Email verification failed. Please try again.",
    variant: "destructive",
    title: "Error",
  },

  // Success messages
  verification_successful: {
    description: "Your email has been successfully verified!",
    variant: "success",
  },

  // Remove messages
  application_deleted: {
    description: "Application deleted successfully.",
    variant: "remove",
  },
};
