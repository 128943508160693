"use client";

import { useSearchParams } from "next/navigation";
import { useEffect } from "react";
import { toast } from "@/components/ui/use-toast";
import { toastMessages } from "@/lib/toast-messages";
import { useRouter } from "next/navigation";
import { usePathname } from "next/navigation";

const ToastHandler = () => {
  const searchParams = useSearchParams();
  const router = useRouter();
  const pathname = usePathname();

  useEffect(() => {
    const action = searchParams.get("action");
    if (!action) return;

    const config = toastMessages[action];
    if (config) {
      toast({
        ...config,
        onOpenChange: (open) => {
          if (!open) {
            // Remove the action parameter while keeping other parameters
            const params = new URLSearchParams(searchParams);
            params.delete("action");
            const query = params.toString();
            const url = query ? `${pathname}?${query}` : pathname;
            router.replace(url, { scroll: false });
          }
        },
      });
    } else {
      // Handle custom messages
      toast({
        description: decodeURIComponent(action).replace(/_/g, " "),
        variant: "default",
        onOpenChange: (open) => {
          if (!open) {
            const params = new URLSearchParams(searchParams);
            params.delete("action");
            const query = params.toString();
            const url = query ? `${pathname}?${query}` : pathname;
            router.replace(url, { scroll: false });
          }
        },
      });
    }
  }, [searchParams]);

  return null;
};

export default ToastHandler;
